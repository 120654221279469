<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "forgetPassword",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "1.3  Forget PIN",
          description: [
            {
              text: "1. Tap on the “Forget PIN” on Log In page",
              imgType: true,
              img: "22.Sign in-input PIN.png",
            },
            {
              text: "2. On the Forget PIN page key in the mobile number that you registered for Kloser account.",
              imgType: true,
              img: "30.Forget PIN – 1.png",
            },
            {
              text: "3. You will receive a 6 digit PIN via SMS to the mobile number",
              imgType: true,
              img: "8.OTP SMS-1 – 1.png",
            },
            {
              text: "4. Reset your new 6 digit PIN",
              imgType: true,
              img: "9.Set PIN - 1 – 1.png",
            },
            {
              text: "5. Congratulations! You have successfully reset security pin.",
              imgType: true,
              img: "reset-status.png",
            },
          ],
        },
      ],
    };
  },
};
</script>
